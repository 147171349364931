<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../assets/retail/banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("solutions.retail.banner_title")}}</h2>
      </div>
    </div>
    <section class="sec-w-100">
      <div class="d-w-1400">
          <div class="d-s-box">
              <div class="d-s-img">
                <img src="../../assets/retail/retail1.png" alt="">
              </div>
          </div>
          <div class="d-l-box">
              <div class="d-content">
                <h3 class="h-text">{{t("solutions.retail.sub_title1")}}</h3>
                <p class="p-text">{{t("solutions.retail.sub_dec1")}}</p>
              </div>
          </div>
      </div>
      <div class="d-w-1400">
          <div class="d-s-box">
              <div class="d-s-img">
                <img src="../../assets/retail/retail2.png" alt="">
              </div>
          </div>
          <div class="d-l-box">
              <div class="d-content">
                <h3 class="h-text">{{t("solutions.retail.sub_title2")}}</h3>
                <p class="p-text">{{t("solutions.retail.sub_dec2")}}</p>
              </div>
          </div>
      </div>
      <div class="d-w-1400">
          <div class="d-s-box">
              <div class="d-s-img">
                <img src="../../assets/retail/retail3.png" alt="">
              </div>
          </div>
          <div class="d-l-box">
              <div class="d-content">
                <h3 class="h-text">{{t("solutions.retail.sub_title3")}}</h3>
                <p class="p-text">{{t("solutions.retail.sub_dec3")}}</p>
              </div>
          </div>
      </div>
    </section>
    <HelpForm/>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import HelpForm from '../../components/HelpForm/index.vue';
export default {
    name: 'RETAIL',
    components: {HelpForm},
    setup() {
        const { t } = useI18n();
        return {
          t,
        };
    },
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 793px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      color: #F4F5F5;
      font-weight: bold;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 22%;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
  }
  .sec-w-100 {
    width: 100%;
    padding: 80px 0;
    .d-w-1400 {
      width: 1140px;
      margin: 50px auto;
      position: relative;
      &::after {
          content: '';
          display: block;
          clear: both;
      }
      .d-s-box {
        width: 400px;
        height: auto;
        padding: 20px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        .d-s-img {
            height: auto;
            // background: #EDE9E9;
            // box-shadow: 0px 0px 20px 0px rgba(102, 102, 102, 0.2);
            border-radius: 16px;
            font-size: 0;
            img {
              width: 100%;
            }
        }
      }
      .d-l-box {
        width: 900px;
        // padding: 100px 200px;
        border: 5px solid #FF9333;
        border-radius: 3px;
        float: right;
        .d-content {
            width: 600px;
            margin: 80px 200px;
            .h-text {
                line-height: 50px;
                color: #333333;
                margin: 0 0 30px;
                &::after {
                    content: '';
                    display: block;
                    width: 100px;
                    height: 5px;
                    background: #FF9333;
                    border-radius: 3px;
                }
            }
            .p-text {
                color: #666666;
                font-size: 12px;
                line-height: 24px;
            }
        }
      }
    }
  }
}
</style>
